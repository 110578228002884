import { clearEvents, publish, subscribe } from "gx-npm-messenger-util";

export const initializeSessionEvent = () => {
  async function sendEvent() {
    publish("WS_OUT", {
      action: "message",
      data: {
        event: "CLIENT_EVENTS",
        events: [{ eventType: "NEW_SESSION" }],
      },
    });
  }

  async function handleInteraction() {
    const last_interaction = sessionStorage.getItem("gx_last_interaction");

    const moment = new Date().getTime();
    sessionStorage.setItem("gx_last_interaction", `${moment}`);

    if (last_interaction === null || moment - Number(last_interaction) > 1000 * 60 * 30) {
      await sendEvent();
    }
  }

  ["click", "keyup", "touchstart", "scroll"].forEach((event) => {
    window.addEventListener(event, handleInteraction);
  });

  subscribe("SESSION_EVENT_LOAD", sendEvent);

  const unload = () => {
    clearEvents("SESSION_EVENT_LOAD");
  };
  return { unload };
};
