import { clearEvents, subscribe, updateStateSubject } from "gx-npm-messenger-util";
import { getAsyncRequest } from "gx-npm-lib";
import { pushDataLayerNonGartnerUserEvent, pushDataLayerUserLoadedEvent } from "./user-data-layer.module";

export const loadUserState = () => {
  const handleLoadUserState = async () => {
    const response = await getAsyncRequest("/api/v2/user/state");
    if (response && response.status === 200 && response.data.data) {
      const newUserState = {
        ...response.data.data,
        hasUserState: true,
      };
      pushDataLayerUserLoadedEvent(newUserState);
      pushDataLayerNonGartnerUserEvent(newUserState);
      updateStateSubject("USER_STATE", newUserState);
    } else {
      throw new Error("Failed to load user state");
    }
  };

  const handleLoadUserStateWithErrorCheck = async () => {
    try {
      await handleLoadUserState();
    } catch (err) {
      const newUserState = {
        hasError: true,
      };
      updateStateSubject("USER_STATE", newUserState);
    }
  };

  const handleClearUserState = () => {
    updateStateSubject("USER_STATE", {});
  };

  subscribe("USER_STATE_LOAD", handleLoadUserStateWithErrorCheck);
  subscribe("USER_STATE_CLEAR", handleClearUserState);

  handleLoadUserState().catch(() => {});

  const unload = () => {
    clearEvents("USER_STATE_LOAD");
    clearEvents("USER_STATE_CLEAR");
  };

  return { unload };
};
