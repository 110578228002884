import { CustomWindowWithDataLayer, DataLayerNonGarterUser, DataLayerUser } from "./user-data-layer.types";

declare let window: CustomWindowWithDataLayer;

const isValidEmail = (email = "") => {
  return !!email && email.indexOf("@gartner.com") === -1 && email.toLowerCase() !== "workflow.testuser01@alphabet.com";
};

export const pushDataLayerUserLoadedEvent = ({
  acquisitionSource,
  email,
  hashedUserId,
  isEntitled,
  userCreatedDate,
  userHMAC,
}: DataLayerUser) => {
  if (!isValidEmail(email) || typeof window.dataLayer !== "object") {
    return;
  }

  window.dataLayer.push({
    event: "user-loaded",
    USER_HASH: hashedUserId,
    USER_ACCESS: isEntitled ? "Full Access" : "Guest",
    COMPANY_DOMAIN: email.split("@")[1],
    USER_INTERCOM_HMAC: userHMAC,
    USER_ACQUISITION_SOURCE: acquisitionSource,
    USER_CREATED_DATE: userCreatedDate,
  });
};

const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
export const pushDataLayerNonGartnerUserEvent = ({
  acquisitionSource,
  email,
  firstName,
  hashedUserId,
  isEntitled,
  lastName,
  userCreatedDate,
  userHMAC,
}: DataLayerNonGarterUser) => {
  if (typeof window.dataLayer !== "object" || !isValidEmail(email)) {
    return;
  }
  window.dataLayer.push({
    event: "non-gartner-ready",
    COMPANY_DOMAIN: email.split("@")[1],
    DAYS_SINCE_CREATION: Math.floor((Date.now() - new Date(userCreatedDate).getTime()) / DAY_IN_MILLISECONDS),
    USER_ACCESS: isEntitled ? "Full Access" : "Guest",
    USER_ACQUISITION_SOURCE: acquisitionSource,
    USER_CREATED_DATE: userCreatedDate,
    USER_EMAIL: email,
    USER_FIRST_NAME: firstName,
    USER_HASH: hashedUserId,
    USER_INTERCOM_HMAC: userHMAC,
    USER_LAST_NAME: lastName,
  });
};
