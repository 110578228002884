import { publish, subscribe } from "gx-npm-messenger-util";
import { CustomWindowWithWsConnection, WsInMsg, WsOutMsg } from "./websocket.types";

declare let window: CustomWindowWithWsConnection;

let ws: WebSocket | null = null;
let pingInterval: ReturnType<typeof setInterval> | null = null;

const waitForOpenConnection = () => {
  return new Promise<void>((resolve, reject) => {
    const maxNumberOfAttempts = 10;
    const intervalTime = 500;
    let currentAttempt = 1;
    const interval = setInterval(() => {
      if (currentAttempt > maxNumberOfAttempts) {
        clearInterval(interval);
        reject(new Error("Maximum number of attempts exceeded"));
      } else if (ws && ws.readyState === ws.OPEN) {
        clearInterval(interval);
        resolve();
      }
      currentAttempt++;
    }, intervalTime);
  });
};

const onOpen = () => {
  pingInterval = setInterval(() => {
    sendMessage({ action: "ping" });
  }, 60 * 1000);
  publish("WS_IN", { event: "CONNECT" });
};

const onError = (e: Event) => {
  console.error(e);
};

const sendMessage = (wsMessage: WsOutMsg) => {
  const message = JSON.stringify(wsMessage);
  if (!ws || [WebSocket.CLOSED, WebSocket.CLOSING].includes(ws.readyState)) {
    connectWebSocket();
    waitForOpenConnection()
      .then(() => {
        ws?.send(message);
      })
      .catch((e) => {
        console.error(e);
      });
  } else if (ws && WebSocket.CONNECTING === ws.readyState) {
    waitForOpenConnection()
      .then(() => {
        ws?.send(message);
      })
      .catch((e) => {
        console.error(e);
      });
  } else {
    ws?.send(message);
  }
};

const onMessage = (e: MessageEvent<string>) => {
  const data: WsInMsg = JSON.parse(e.data);
  if (data?.event !== "pong") {
    publish("WS_IN", data);
  }
};

const connectWebSocket = () => {
  if (ws && [WebSocket.OPEN, WebSocket.CONNECTING].includes(ws.readyState)) {
    return ws;
  }
  disconnectWebSocket();
  ws = new WebSocket(window.wsConnection);
  ws.onerror = onError;
  ws.onmessage = onMessage;
  ws.onopen = onOpen;
  return ws;
};

const disconnectWebSocket = () => {
  if (pingInterval) {
    clearInterval(pingInterval);
  }
  if (ws) {
    ws.close();
  }
};

export const initializeWebSocket = () => {
  subscribe("WS_CONNECT", connectWebSocket);
  subscribe("WS_DISCONNECT", disconnectWebSocket);
  subscribe("WS_OUT", sendMessage);
  return connectWebSocket();
};
