import * as singleSpa from "single-spa";
import microFrontendLayout from "./microfrontend-layout.html";
import loadFullStorySnippet from "./fullstory.module";
import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";
import { getFeatureFlags } from "./feature-flags-request";
import { initializeWebSocket } from "./websocket.module";
import { initializeSessionEvent } from "./session-capture.module";
import { loadUserState } from "./user-state.module";

const routes = constructRoutes(microFrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

// Delay starting the layout engine until the styleguide CSS is loaded
const layoutEngine = constructLayoutEngine({
  routes,
  applications,
  active: false,
});

applications.forEach(singleSpa.registerApplication);
layoutEngine.activate();
getFeatureFlags();
initializeSessionEvent();
initializeWebSocket();
loadUserState();
loadFullStorySnippet();

singleSpa.addErrorHandler((err) => {
  const appName = err.appOrParcelName;
  if (singleSpa.getAppStatus(appName) === singleSpa.LOAD_ERROR) {
    const resolvedApp = System.resolve(appName);
    System.delete(resolvedApp);
    setTimeout(() => {
      System.import(resolvedApp)
        .then(() => singleSpa.triggerAppChange())
        .catch(() => console.error("failed to reload bundle: ", appName));
    }, 1000);
  }
});
singleSpa.start();
